<!-- 进行付款 已付款 退款 -->
<template>
  <div class="payment">
    <div class="content">
      <div class="box">
        <!-- 课程详情 -->
        <div class="course">
          <div class="title">
            <span></span>
            <p>您购买的课程</p>
          </div>
          <div class="course_info">
            <div class="course_info_left">
              <div class="img">
                <el-image
                  style="height: 197px"
                  src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                ></el-image>
              </div>
              <div class="course_info_name">
                <p>[新人]编程代码</p>
                <p><span>价格：</span><span>¥4587</span></p>
              </div>
            </div>
            <div class="course_info_right">
              <div>查看详情</div>
            </div>
          </div>
        </div>
        <!-- 课程提醒发生至 -->
        <div class="phone">
          <div class="title">
            <span></span>
            <p>课程提醒发生至</p>
          </div>
          <div class="phone_info">
            <div class="phoneCode">
              <p>手机号：199****0817</p>
              <p>接受信息提醒</p>
            </div>
            <div class="editPhone">修改</div>
          </div>
        </div>
        <!-- 支付方式 -->
        <div class="paymentMode">
          <div class="title">
            <span></span>
            <p>确认支付方式</p>
          </div>
          <div class="pamentChoice">
            <div class="pay">
              <p>确认平台</p>
              <div class="imgs">
                <div
                  class="img"
                  v-for="(item, index) in payList"
                  :key="index"
                  @click="clickPay(index)"
                >
                  <el-image
                    style="width: 18px; height: 18px; margin-right: 10px"
                    :src="
                      require('../../assets/images/me/' + item.select + '.png')
                    "
                  ></el-image>
                  <el-image
                    style="width: 41px; height: 41px"
                    :src="item.payImg"
                  ></el-image>
                </div>
              </div>
            </div>
            <div class="remarks">
              <p>我要留言</p>
              <el-input
                type="textarea"
                :rows="4"
                placeholder="请输入内容"
                v-model="remarks"
              >
              </el-input>
            </div>
          </div>
        </div>
        <!-- 订单信息 -->
        <div class="order">
          <div class="title">
            <span></span>
            <p>订单信息</p>
          </div>
          <div class="order_info">
            <div class="order_left">
              <p>编号：125472564</p>
              <p>付款时间：2021-04-28</p>
              <p>
                支付方式：
                <el-image
                  style="width: 41px; height: 41px"
                  :src="require('../../assets/images/me/zfb.png')"
                ></el-image>
              </p>
            </div>
            <div class="order_right">
              <p><span>实付款</span><span>¥4587</span></p>
              <div>退款</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 付款 -->
      <div class="money_box">
        <div class="title">
          <span></span>
          <p>需支付金额</p>
        </div>
        <div class="money">
          <div class="money_left">
            <p>课程价格：¥4587</p>
            <p>优惠码：¥0</p>
            <p>应付金额：¥0</p>
          </div>
          <div class="money_right">
            <p>¥4587</p>
            <div>付款</div>
          </div>
        </div>
      </div>
      <!-- 退款 -->
      <div class="refund_box">
        <div class="title">
          <span></span>
          <p>退款信息</p>
        </div>
        <div class="refund">
          <div class="refund_left">
            <div class="ipt">
              <span>退款信息：</span><el-input v-model="refundValue"></el-input>
            </div>
            <div class="money">
              <span>退款金额：</span>
              <div>
                <p>¥4587</p>
                <p>最多退款4587，不含优惠卷。</p>
              </div>
            </div>
          </div>
          <div class="refund_right">
            <div>退款</div>
          </div>
        </div>
      </div>
      <!-- 是否同意协议 -->
      <div class="drawing">
        <div @click="clickDrawing">
          <el-image
            v-if="drawingShow"
            style="width: 18px; height: 18px; margin-right: 10px"
            :src="require('../../assets/images/me/select.png')"
          ></el-image>
          <el-image
            v-if="!drawingShow"
            style="width: 18px; height: 18px; margin-right: 10px"
            :src="require('../../assets/images/me/unSelect.png')"
          ></el-image>
          <span>本人同意并接受《垦科鼎精品课服务条款》</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      payList: [
        {
          select: "unSelect",
          payImg: require("../../assets/images/me/zfb.png"),
        },
        {
          select: "unSelect",
          payImg: require("../../assets/images/me/wx.png"),
        },
      ],
      payIndex: 0,
      //   备注留言
      remarks: "",
      //   协议显示
      drawingShow: true,
      //   退款信息
      refundValue: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.payList[this.payIndex].select = "select";
  },
  //方法集合
  methods: {
    //   点击付款方式
    clickPay(index) {
      this.payIndex = index;
      this.payList.forEach((item, index2) => {
        if (index == index2) {
          this.payList[index2].select = "select";
        } else {
          this.payList[index2].select = "unSelect";
        }
      });
    },
    clickDrawing() {
      this.drawingShow = !this.drawingShow;
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
.payment {
  background-color: #fff;
  padding-top: 8%;
  .title {
    display: flex;
    align-items: center;
    span {
      display: inline-block;
      width: 2px;
      height: 21px;
      background-color: #28b58c;
    }
    p {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313131;
      line-height: 33px;
      margin-left: 10px;
    }
  }
  .box {
    background-color: #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    padding: 2% 4.9% 2% 2.6%;

    .course {
      .course_info {
        height: 100%;
        margin: 1.5% 0 0 1%;
        padding-bottom: 4.4%;
        border-bottom: 1px solid #979797;

        display: flex;
        justify-content: space-between;
        .course_info_left {
          display: flex;

          .img {
            width: 27%;
          }
          .course_info_name {
            margin-left: 2.5%;
            p {
              &:nth-child(1) {
                font-size: 36px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303030;
                line-height: 50px;
              }
              &:nth-child(2) {
                margin-top: 6%;

                span {
                  font-size: 21px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  line-height: 29px;
                  &:nth-child(1) {
                    color: #303030;
                  }
                  &:nth-child(2) {
                    color: #e94c32;
                  }
                }
              }
            }
          }
        }
        .course_info_right {
          margin-top: 3%;
          div {
            width: 88px;
            height: 30px;
            background: #d8d8d8;
            border-radius: 6px;
            padding: 0 1rem;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 30px;
            background: #d8d8d8;
            box-sizing: border-box;
          }
        }
      }
    }
    .phone {
      padding-top: 1.8%;

      .phone_info {
        margin: 4% 0 0 1.2%;
        padding-bottom: 2%;
        display: flex;
        border-bottom: 1px solid #979797;

        .phoneCode {
          p {
            &:nth-child(1) {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #303030;
              line-height: 22px;
            }
            &:nth-child(2) {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #828282;
              line-height: 17px;
              text-align: right;
              margin-top: 7.5%;
            }
          }
        }
        .editPhone {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e94c32;
          line-height: 22px;
          margin-left: 3.2%;
        }
      }
    }
    .paymentMode {
      padding-top: 1.8%;

      .pamentChoice {
        margin: 3.4% 0 0 1.2%;
        .pay {
          display: flex;
          align-items: center;
          margin-bottom: 2%;

          .imgs {
            display: flex;
            margin-left: 3%;
            .img {
              display: flex;
              align-items: center;
              margin-right: 35%;
            }
          }
        }
        .remarks {
          display: flex;
          ::v-deep {
            .el-textarea {
              margin-left: 3%;
            }
            .el-textarea__inner {
              width: 60%;
            }
          }
        }
        .pay,
        .remarks {
          p {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 20px;
            white-space: nowrap;
          }
        }
      }
    }
    .order {
      padding-top: 1.8%;

      .order_info {
        margin: 2% 0 1% 1%;
        display: flex;
        justify-content: space-between;

        .order_left {
          p {
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #303030;
            line-height: 22px;
            margin-bottom: 14%;
            display: flex;
            align-items: center;
          }
        }
        .order_right {
          display: flex;
          align-items: flex-end;
          p {
            margin-right: 30px;

            span {
              font-size: 24px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              line-height: 33px;
              &:nth-child(1) {
                color: #2f2f2f;
              }
              &:nth-child(2) {
                color: #e94c32;
              }
            }
          }
          div {
            width: 96px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #8d8d8d;
            border-radius: 2px;
            font-size: 21px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
  .money_box {
    background-color: #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    padding: 1.5% 4.9% 2% 2.6%;
    margin-top: 4%;
    .money {
      margin: 2% 0 2% 1%;
      display: flex;
      justify-content: space-between;
      .money_left {
        p {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #303030;
          line-height: 20px;
          margin-bottom: 5%;
        }
      }
      .money_right {
        display: flex;
        align-items: flex-end;
        p {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e94c32;
          line-height: 33px;
          margin-right: 30px;
        }
        div {
          width: 96px;
          height: 40px;
          line-height: 40px;
          background: #e94c32;
          border-radius: 2px;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }
    }
  }
  .refund_box {
    background-color: #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.08);
    padding: 1.5% 4.9% 2% 2.6%;
    margin-top: 4%;
    .refund {
      display: flex;
      justify-content: space-between;
      margin: 2% 0 0% 1%;

      .refund_left {
        .ipt {
          margin-bottom: 10%;
          ::v-deep {
            .el-input__inner {
              height: 30px;

              border: 1px solid #979797;
              border-radius: 0;
            }
          }
        }
        .money {
          display: flex;
          div {
            p {
              &:nth-child(1) {
                font-size: 24px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #303030;
              }
              &:nth-child(2) {
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #858585;
                line-height: 17px;
              }
            }
          }
        }
        .ipt {
          display: flex;
          align-items: center;
          span {
            white-space: nowrap;
          }
        }
      }
      .refund_right {
        display: flex;
        align-items: flex-end;

        div {
          width: 86px;
          height: 40px;
          background: #e94c32;
          border-radius: 2px;
          font-size: 21px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 40px;
          text-align: center;
        }
      }
    }
  }
  .drawing {
    margin-top: 2%;

    div {
      display: flex;
      align-items: center;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #303030;
        line-height: 20px;
      }
    }
  }
}
</style>
